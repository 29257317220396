/* Font Definitions */

@font-face {
  font-family: 'Sharp Sans';
  src: url('https://cdn.barscience.us/fonts/SharpSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url('https://cdn.barscience.us/fonts/SharpSans-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url('https://cdn.barscience.us/fonts/SharpSans-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url('https://cdn.barscience.us/fonts/SharpSans-SemiboldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url('https://cdn.barscience.us/fonts/SharpSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url('https://cdn.barscience.us/fonts/SharpSans-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url('https://cdn.barscience.us/fonts/SharpSans-Extrabold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url('https://cdn.barscience.us/fonts/SharpSans-ExtraboldItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
}


/* Style Resets */

body {
  font-family: '"Sharp Sans", "Open Sans", sans-serif';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #151615;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin: 0;
}